import React from "react";
import { object } from "prop-types";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { refactorDate } from "shared/functions.js";
import { BlogEntry } from "components/BlogEntry";
import { Layout } from "components/Layout";

export default function PostTemplate({ data: { mdx }, location }) {
  return (
    <Layout
      location={location}
      title={mdx.frontmatter.title + " - www.srebniak.pl"}
    >
      <BlogEntry
        key={mdx.id}
        date={refactorDate(mdx.frontmatter.date)}
        title={mdx.frontmatter.title}
        author={mdx.frontmatter.author}
        heroImage={mdx.frontmatter.heroImage}
        description={mdx.frontmatter.description}
        categories={mdx.frontmatter.categories}
      />
      <MDXRenderer
        localImages={mdx.frontmatter.localImages}
        localDocuments={mdx.frontmatter.localDocuments}
        localVideos={mdx.frontmatter.localVideos}
      >
        {mdx.body}
      </MDXRenderer>
    </Layout>
  );
}

PostTemplate.propTypes = {
  data: object.isRequired,
  mdx: object,
  location: object.isRequired,
};

export const pageQuery = graphql`
  query PostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date
        author {
          name
          link
        }
        description
        categories {
          name
          subcategoryOf
          link
        }
        heroImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        localImages {
          childImageSharp {
            gatsbyImageData
          }
        }
        localDocuments {
          publicURL
        }
        localVideos {
          publicURL
        }
      }
    }
  }
`;
